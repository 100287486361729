const BACKEND = process.env.VUE_APP_BACKEND;

const PARAM = Object.freeze({
  LIMIT: 30,
  MAX_LIMIT: 100,
  TYPE: {
    CW20: 'cw20',
    NATIVE: 'native',
  },
});

const ROUTE = Object.freeze({
  BANK: `${BACKEND}/v1/bank`,
  CW20: `${BACKEND}/v1/cw20`,
  USERS: `${BACKEND}/v1/users`,
});

export default Object.freeze({
  PARAM,
  ROUTE,
});
