<template>
  <v-text-field
    id="cursor"
    dense
    name="cursor"
    label="cursor"
    outlined
    v-model="cursor"
  ></v-text-field>
</template>

<script>
export default {
  name: 'ParamCursor',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  computed: {
    cursor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
