<template>
  <v-text-field
    id="recipient"
    dense
    name="recipient"
    label="Recipient"
    outlined
    v-model="recipient"
  ></v-text-field>
</template>

<script>
export default {
  name: 'SendRecipient',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  computed: {
    recipient: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
