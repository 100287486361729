const PATH = Object.freeze({
  ROOT: '/',
  NOT_FOUND: '/:notFound(.*)',
  LOGIN: '/login',
  HOME: '/home',
  BANK: '/bank',
  BANK_BALANCE: 'balance',
  BANK_RECEIVED: 'received',
  BANK_SEND: 'send',
  BANK_SENT: 'sent',
  CW20: '/cw20',
  CW20_RECEIVED: 'received',
  CW20_SEND: 'send',
  CW20_SENT: 'sent',
  USERS: '/users',
  USERS_DETAIL: 'detail',
  USERS_CREATE: 'create',
});

const NAME = Object.freeze({
  NOT_FOUND: 'not-found',
  LOGIN: 'login',
  HOME: 'home',
  BANK: 'bank',
  BANK_BALANCE: 'bank-balance',
  BANK_RECEIVED: 'bank-received',
  BANK_SEND: 'bank-send',
  BANK_SENT: 'bank-sent',
  CW20: 'cw20',
  CW20_RECEIVED: 'cw20-received',
  CW20_SEND: 'cw20-send',
  CW20_SENT: 'cw20-sent',
  USERS: 'users',
  USERS_DETAIL: 'users-detail',
  USERS_CREATE: 'users-create',
});

const AUTH = Object.freeze({
  USER_ROLE: {
    ANY: 'any',
    AUTHORIZED: 'authorized',
    NOT_AUTHORIZED: 'notAuthorized',
  },
});

export default Object.freeze({
  AUTH,
  NAME,
  PATH,
});
